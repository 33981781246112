
import CarMarker from 'assets/images/vehicleIcons/car.svg'
import BusMarker from 'assets/images/vehicleIcons/bus.svg'
import TruckMarker from 'assets/images/vehicleIcons/truck.svg'
import hardAcceleration from 'assets/images/hardAcclerate.png';
import darkHardAcclerate from 'assets/images/darkHardAcclerate.png';
import hardBrake from 'assets/images/hardBrake.png';
import darkHardBrake from 'assets/images/darkHardBrake.png';
import overSpeeding from 'assets/images/overSpeeding.png';
import darkOverspeeding from 'assets/images/darkOverspeeding.png';
import sharpTurn from 'assets/images/sharpTurn.png';
import darkSharpTurn from 'assets/images/darkSharpTurn.png';
import collisionWarning from 'assets/images/collisionWarning.png';
import darkCollisionWarning from 'assets/images/darkCollisionWarning.png';
import collisionEvent from 'assets/images/collisionEvent.png';
import darkCollisionEvent from 'assets/images/darkCollisionEvent.png';
import distraction from 'assets/images/distraction.png';
import darkDistraction from 'assets/images/darkDistraction.png';
import cameraObstruction from 'assets/images/cameraObstruction.png';
import darkCameraObstruction from 'assets/images/darkCameraObstruction.png';
import drowsy from 'assets/images/drowsy.png';
import darkDrowsy from 'assets/images/darkDrowsy.png';
import tailgating from 'assets/images/tailgating.png';
import darkTailgating from 'assets/images/darkTailgating.png';
import seatBelt from 'assets/images/seatBelt.png';
import darkSeatBelt from 'assets/images/darkSeatBelt.png';
import driver from 'assets/images/drivers.svg';
import GeoLocation from 'assets/images/GeoLocation.png';
import warningIcon from 'assets/images/warn.png';
import nightTruckIcon from 'assets/images/nighttruckicon.png';
import truckovertimeIcon from 'assets/images/truckovertimeicon.png';

import { truckIconString, carIconString, busIconString, tankerTruckIconString } from 'assets/svg/utils'
import { cameraLayout1, cameraLayout2, cameraLayout3 } from 'assets/svg/utils'

export const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

export const oddRequestStatus = {
  created: 'Pending',
  in_progress: 'Processing',
  completed: 'Ready',
  failed: 'Request Failed',
};

export const trackingResource = {
  title: 'Fleet',
  filterByText: 'Filter by',
  sortOptions: {
    all: 'All',
    active: 'Active',
    inactive: 'Inactive',
    offline: 'Offline',
    parkMode : 'Park Mode',
    // gpsError : 'GPS Error'
  },
};


export const trackingConfig = {
  vehicleSortingOptions: [
    { key: 'all', value: trackingResource.sortOptions.all },
    { key: 'active', value: trackingResource.sortOptions.active },
    { key: 'inactive', value: trackingResource.sortOptions.inactive },
    { key: 'offline', value: trackingResource.sortOptions.offline },
    { key: 'parkMode', value: trackingResource.sortOptions.parkMode },
    // { key: 'gpsError', value: trackingResource.sortOptions.gpsError },

  ],
};

export const vehicleStatusEnum = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OFFLINE: 'offline',
};

export const mapInitialViewPortState = {
  width: '100%',
  height: '100%',
  zoom: 4,
  latitude: 22.770009,
  longitude: 80.8776998,
};

export const apiRequestStatusCodes = {
  SUCCESS: [200, 201, 204],
  FORBIDDEN: [404],
  CONFLICT: [409],
  NOT_ACCEPTABLE: [406],
};

export const eventTypes = {
  hard_braking: 'Hard Braking',
  over_speeding: 'Over Speeding',
  collision_warning: 'Collision Warning',
  distraction_warning: 'Distraction Warning',
  tailgating: 'Tailgating',
  drowsiness_warning: 'Drowsiness',
  collision_event: 'Collision Event',
  camera_obstruction: 'Camera Obstruction',
  headway_monitoring: 'Headway Monitoring',
};

export const otherNotificationTypes = {
  unauthorized_driver: 'Unauthorized Driver',
  geofence: 'Geo Fence',
  night_driving_alert: 'Night Driving',
  max_daily_driving_exceeded: 'Max. Daily Driving Exceeded',
  breakless_driving_limit: 'Continuous Driving without break',
  seat_belt_alert: 'Seatbelt Alert',
};

export const deviceAlertNotificationTypes = {
  distraction_alert: 'Distraction Alert',
  l2_drowsy_alert: 'L2 Drowsy Alert',
  l3_drowsy_alert: 'L3 Drowsy Alert',
  camera_obstruction_alert: 'Camera Obstruction Alert',
  fcw_alert: 'Forward Collision Warning'
};

export const events = {
  eventDetails: {
    tags: [
      { label: 'Front vehicle braking', value: 'Front vehicle braking' },
      { label: 'Tailgating', value: 'Tailgating' },
      { label: 'Detection misfire', value: 'Detection misfire' },
      { label: 'Heavy traffic', value: 'Heavy traffic' },
      { label: 'Object passes from front', value: 'Object passes from front' },
      { label: 'Object from opposite traffic', value: 'Object from opposite traffic' },
      {
        label: 'Pedestrian, vehicle stopped already',
        value: 'Pedestrian, vehicle stopped already',
      },
      { label: 'Object overtaking vehicle', value: 'Object overtaking vehicle' },
      { label: 'Object too far on side', value: 'Object too far on side' },
      { label: 'Parked object detected', value: 'Parked object detected' },
      { label: 'Vehicle overtaking object', value: 'Vehicle overtaking object' },
      { label: 'Warning duration too short', value: 'Warning duration too short' },
      { label: 'Video not clear', value: 'Video not clear' },
    ],
  },
};

export const user = {
  profile: {
    roles: {
      client_admin: 'Client Admin',
      super_admin: 'Super Admin',
      operation_admin: 'Operation Admin',
    },
  },
};


export const onDemandDataResource = {
  vehicleListSection: {
    title: 'On Demand Data',
  },
  oddFormSection: {
    title: 'On Demand Data (ODD)',
    description: 'Select vehicle and date to preview driving behavior',
    formLabel: {
      date: 'Date',
      duration: 'Duration',
      startTime: 'Start Time',
    },
    actionBtn: 'Generate',
  },
  oddRequestListSection: {
    title: 'Recent ODD Requests',
    description: "Last 7 day's odd requests",
  },
  warningMessage: {
    startDate: 'Please select Start Date for preview.',
    startTime: 'Please select Start Time for preview.',
    endTime: 'Please select End Time for preview.',
  },
  genericErrorMessage: 'Something went wrong!',
  failedRequestMessage: {
    SENSOR_DATA_NOT_AVAILABLE: 'Sensor Data not Available',
    DMS_VIDEO_NOT_AVAILABLE: 'DMS video not available',
    RMS_VIDEO_NOT_AVAILABLE: 'RMS video not available',
    'DMS_VIDEO_NOT_AVAILABLE,RMS_VIDEO_NOT_AVAILABLE': 'DMS and RMS video not available',
    'RMS_VIDEO_NOT_AVAILABLE,DMS_VIDEO_NOT_AVAILABLE': 'DMS and RMS video not available'
  }
};

export const VehicleClassMapping = {
  car: {
    classes: [
      "Micro Car",
      "Mini car",
      "Small car",
      "Compact car",
      "Compact luxury car",
      "Medium car",
      "Medium luxury car",
      "Large car",
      "Large luxury car",
      "Full-size luxury car",
      "Superluxury car",
      "Sports compact car",
      "Sports car",
      "Small SUV",
      "Compact SUV",
      "Medium SUV",
      "Large SUV",
      "Full-size SUV",
      "Supercar",
      "Mini MPV",
      "Small MPV",
      "Compact MPV",
      "Medium MPV",
      "Large MPV",
      "Low-cost SUV",
      "Grand tourer",
      "Small crossover",
      "Compact crossover",
      "Medium crossover",
      "Large crossover",
      "Full-size crossover",
    ],
    img: CarMarker,
    svgString: carIconString,
    class: 'car',
    height: 40
  },
  bus: {
    classes: ["bus"],
    img: BusMarker,
    svgString: busIconString,
    class: 'bus',
    height: 55
  },
  truck: {
    classes: ["Pickup truck"],
    img: TruckMarker,
    svgString: truckIconString,
    class: 'truck',
    height: 55
  }
};


export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
};

export const alertTypes = {
  hard_acceleration: {
    icon: darkHardAcclerate,
    iconLight: hardAcceleration,
    title: 'Hard Acceleration',
  },
  hard_braking: {
    icon: darkHardBrake,
    iconLight: hardBrake,
    title: 'Hard Braking',
  },
  over_speeding: {
    icon: darkOverspeeding,
    iconLight: overSpeeding,
    title: 'Over Speeding',
  },
  sharp_turn: {
    icon: darkSharpTurn,
    iconLight: sharpTurn,
    title: 'Sharp Turn',
  },
  collision_warning: {
    icon: darkCollisionWarning,
    iconLight: collisionWarning,
    title: 'Collision Warning',
  },
  drowsiness_warning: {
    icon: darkDrowsy,
    iconLight: drowsy,
    title: 'Drowsy',
  },
  distraction_warning: {
    icon: darkDistraction,
    iconLight: distraction,
    title: 'Distraction',
  },
  tailgating: {
    icon: darkTailgating,
    iconLight: tailgating,
    title: 'Tailgating',
  },
  collision_event: {
    icon: darkCollisionEvent,
    iconLight: collisionEvent,
    title: 'Collision Event',
  },
  camera_obstruction: {
    icon: darkCameraObstruction,
    iconLight: cameraObstruction,
    title: 'Camera Obstruction',
  },
  seat_belt: {
    icon: darkSeatBelt,
    iconLight: seatBelt,
    title: 'Seat Belt',
  },
  headway_monitoring: {
    icon: warningIcon,
    iconLight: warningIcon,
    title: 'Headway Monitoring',
  },
};

export const EventCategories = {
  looking_sides : "Looking Sides",
  looking_down_phone_infotainment : "Looking Down, Using Phone, Using Infotainment Cluster",
  talking_on_phone : "Talking on the phone",
  smoking : "Smoking",
  l1_yawning : "L1 Yawning",
  l2_feeling_sleepy : "L2 Feeling Sleepy",
  l3_sleeping : "L3 Sleeping",
}


export const deviceAlertTypes = {
  SEATBELT_ALERT_FROM_CLOUD: {
    title: 'Seatbelt Alert',
    icon: darkSeatBelt,
    iconLight: seatBelt,
  },
  DISTRACTION_ALERT : {
    title: 'Distraction Alert',
    icon: darkDistraction,
    iconLight: distraction,
  },
  L2_DROWSY_ALERT : {
    title: 'L2 Drowsy Alert',
    icon: darkDrowsy,
    iconLight: drowsy,
  },
  L3_DROWSY_ALERT : {
    title: 'L3 Drowsy Alert',
    icon: darkDrowsy,
    iconLight: drowsy,
  },
  CAMERA_OBSTRUCTION_ALERT : {
    title: 'Camera Obstruction Alert',
    icon: darkCameraObstruction,
    iconLight: cameraObstruction,
  },
  FCW_ALERT : {
    title: 'Forward Collision Warning',
  },
  SEATBELT_ALERT : {
    title: 'Seatbelt Alert',
    icon: darkSeatBelt,
    iconLight: seatBelt
  },
  NIGHT_DRIVING_ALERT : {
    title: 'Night Time Driving',
    icon: nightTruckIcon,
    iconLight: nightTruckIcon
  },
  MAX_DAILY_DRIVING_EXCEEDED : {
    title: 'Max. Daily Driving Exceeded',
    icon: truckovertimeIcon,
    iconLight: truckovertimeIcon
  },
  BREAKLESS_DRIVING_LIMIT : {
    title: 'Continuous Driving without break',
    icon: truckovertimeIcon,
    iconLight: truckovertimeIcon
  },
};

export const unknowndriverNotification = {
    icon: driver,
    iconLight: driver,
    title: 'Unauthorized Driver Detected',
};

export const OnScreenWarning = {
  drowsiness_warning  : "Drowsy Driver"
}
export const geofenceNotification = {
    icon: GeoLocation,
    iconLight: GeoLocation,
};

export const nightDrivingNotification = {
    icon: nightTruckIcon,
    iconLight: nightTruckIcon,
};


export const liveVideoLayoutMapper = [
  { order: 0, icon: cameraLayout1, maxVideo: 18, fullScreenSupport: true },
  { order: 1, icon: cameraLayout2, maxVideo: 8, fullScreenSupport: true },
  { order: 2, icon: cameraLayout3, maxVideo: 7, fullScreenSupport: true, monitorMode: true }
]

export const cameraType = {
  DUAL_CAM: 'dual_cam',
  MULTI_CAM: 'multi_cam',
  DUAL_MULTI_CAM: 'dual_multi_cam'
}


export const IncidentStatus = {
  NON_FUNCTIONAL_DEVICES : 'non_functional_devices',
  TECHNICAL_REMOTE_ISSUE : 'technical_remote_issue',
  HARDWARE_REMOVAL_REPLACEMENT : 'hardware_removal_replacement',
  UNDER_MAINTENANCE : 'under_maintenance',
  READY_TO_SHIP : 'ready_to_ship',
  HARDWARE_REINSTALLATION : 'hardware_reinstallation',
  UNDER_REVIEW : 'under_review',
  RESOLVED: 'resolved',

};
export const  IncidentStatusLabel = {
  [IncidentStatus.NON_FUNCTIONAL_DEVICES] : 'Non-Functional Devices',
  [IncidentStatus.TECHNICAL_REMOTE_ISSUE] : 'Technical/Remote Issue',
  [IncidentStatus.HARDWARE_REMOVAL_REPLACEMENT] : 'Hardware Removal/Replacement',
  [IncidentStatus.UNDER_MAINTENANCE] : 'Under Maintenance',
  [IncidentStatus.READY_TO_SHIP] : 'Ready To Ship',
  [IncidentStatus.HARDWARE_REINSTALLATION] : 'Hardware Reinstallation',
  [IncidentStatus.UNDER_REVIEW] : 'Under Review',
  [IncidentStatus.RESOLVED] : 'Resolved',
}

export const MaintenanceTicketStatus = {
  todo: 'To Do',
  inprogress: 'In Progress',
  review: 'Review',
  resolved: 'Resolved',
  notanissue: 'Not An Issue',
  onhold: 'On Hold'
}

export const MaintenanceTicketEnum = {
  TODO: 'todo',
  INPROGRESS: 'inprogress',
  REVIEW: 'review',
  RESOLVED: 'resolved',
  NOTANISSUE: 'notanissue',
  ONHOLD: 'onhold'
}

export const MaintenanceTicketStatusRestrictions = {
  todo: ['inprogress', 'notanissue'],
  inprogress: ['review', 'resolved', 'onhold'],
  review: ['inprogress', 'resolved', 'onhold'],
  resolved: [],
  notanissue: ['todo'],
  onhold: ['inprogress', 'review']
}


export const IncidentActivityType = {
  COMMENT: 'comment',
  ACTIVITY: 'activity'
};

export const IncidentPriority = {
  CRITICAL: 'critical',
  HIGH: 'high',
  MODERATE: 'moderate',
  LOW: 'low',
  PLANNING: 'planning'
}

export const IncidentType = {
  ALL: 'all',
  ASSIGNED_TO_ME: 'assigned_to_me',
  REPORTED_BY_ME: 'reported_by_me'
}

export const SortType = {
  ASC: 'ASC',
  DSC: 'DCS',
  NEUT: 'NEUT'
}

export const NotificationType  = {

   NEW_EVENT : 'new_event',
  
   ODD_REQUEST_UPDATE : 'odd_request_update',
  
   NEW_INCIDENT : 'new_incident',
  
   INCIDENT_UPDATE : 'incident_update',
  
   NEW_INCIDENT_COMMENT : 'new_incident_comment',
  
}
  
export const DBAI_CLIENT_ID = '6165243c479bac001e5172e3';

export const VehicleTypeOptions =['commercial', 'private'];

export const VehicleClassOptions = [
  'Pickup truck',
  'Tanker truck',
  'bus',
  'Small car',
  'Medium car',
  'Large car',
]

export const cameraTypeName = {
  dual_cam: 'Dual Camera',
  multi_cam: 'Multi Camera',
  dual_multi_cam: 'Dual + Multi Camera'
}

export const IgnitionStatus = {
  ON: 'on',
  OFF: 'off'
}

export const OnboardingTypeEnum = {
  ONBOARDING_FROM_EVENT : 'ONBOARDING_FROM_EVENT',
  ANNOTATION_FROM_EVENT: 'ANNOTATION_FROM_EVENT',
  ONBOARDING_FROM_BEST_FACE : 'ONBOARDING_FROM_BEST_FACE'
}

export const OnboardingAction = {
  ONBOARD : 'ONBOARD',
  ADD_FACE : 'ADD_FACE'
}


export const CallActionEnum = {
  INITIATE: 'initiate_call',
  INITIATE_ACK: 'initiate_call_ack',
  READY: 'ready',
  READY_ACK: 'ready_ack',
  IN_PROGRESS_ACK: 'call_inprogress_ack',
  IN_PROGRESS: 'call_inprogress',
  END: 'end_call',
  END_ACK: 'end_call_ack'
}


export const CallStatusEnum = {
  INITIATED : 'Initiated',
  INPROGRESS : 'Inprogress',
  ENDED : 'Ended',
}

export const StreamActionEnum = {
  INITIATE: 'initiate_stream',
  INITIATE_ACK: 'initiate_stream_ack',
  IN_PROGRESS_ACK: 'call_inprogress_ack',
  IN_PROGRESS: 'stream_inprogress',
  END: 'end_stream',
  END_ACK: 'end_stream_ack'
}

export const VehicleFeatures = {
  CLOUD_TELEPHONY: "CLOUD_TELEPHONY",
  MEDIAMTX_LIVE_STREAMING: "MEDIAMTX_LIVE_STREAMING",
  PRIVACY_MODE: "PRIVACY_MODE"
}

export const VehicleFeaturesForConfig = {
  CLOUD_TELEPHONY: "CLOUD_TELEPHONY",
  MEDIAMTX_LIVE_STREAMING: "MEDIAMTX_LIVE_STREAMING",
}

export const materialColors = [
  "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#8BC34A",
  "#EA80FC", "#03A9F4", "#00BCD4", "#009688", "#4CAF50",
  "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800",
  "#FF5722", "#795548", "#9E9E9E", "#607D8B",
  "#E57373", "#F06292", "#BA68C8", "#9575CD",
  "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC",
  "#81C784", "#AED581", "#FFD54F", "#FFB74D", "#FF8A65",
  "#A1887F", "#E0E0E0", "#90A4AE", "#D32F2F", "#C2185B",
  "#7B1FA2", "#512DA8", "#303F9F", "#1976D2", "#0288D1",
  "#0097A7", "#00796B", "#388E3C", "#689F38", "#AFB42B",
  "#FBC02D", "#FFA000", "#F57C00", "#E64A19", "#5D4037",
  "#616161", "#455A64", "#FF8A80", "#FF80AB", "#EA80FC",
  "#B388FF", "#8C9EFF", "#82B1FF", "#80D8FF", "#84FFFF",
  "#A7FFEB", "#B9F6CA", "#CCFF90", "#FFE57F", "#FFD180",
  "#FF9E80", "#D50000", "#C51162", "#AA00FF", "#6200EA",
  "#304FFE", "#2962FF", "#0091EA", "#00B8D4", "#00BFA5",
  "#00C853", "#64DD17", "#AEEA00", "#FFD600", "#FFAB00",
  "#FF6D00", "#DD2C00", "#6D4C41", "#757575", "#BDBDBD",
  "#004D40", "#FF6E40", "#00C853", "#00BFA5", "#40C4FF",
  "#304FFE", "#3F51B5", "#E91E63", "#00BCD4", "#689F38",
  "#F57C00", "#FF9100", "#FF1744", "#651FFF", "#00C853",
  "#C6FF00", "#FFD600", "#FFAB00", "#FF6D00", "#DD2C00"
];


export const OddVideoAvailability = {
  RMS_VIDEO_AVAILABLE : 'RMS_VIDEO_AVAILABLE',
  DMS_VIDEO_AVAILABLE : 'DMS_VIDEO_AVAILABLE',
  RMS_VIDEO_PARTIALLY_AVAILABLE : 'RMS_VIDEO_PARTIALLY_AVAILABLE',
  DMS_VIDEO_PARTIALLY_AVAILABLE : 'DMS_VIDEO_PARTIALLY_AVAILABLE'
}

export const incidentSupportLevel = {
  L1 : 10800000, //(in miliseconds) 3 hours
  L2 : 172800000, //(in miliseconds) 48 hours
  L3 : 604800000, //(in miliseconds) 7 days
}

export const DeviceServicesList = [
  "dbaialert.service",
  "dbaicloudbestface.service",
  "dbaiclouddata.service",
  "dbaicloudtelephony.service",
  "dbaicloudvideo.service",
  "dbaiconfigsync.service",
  "dbaidmsframepublisher.service",
  "dbaidms.service",
  "dbaidmsstreamer.service",
  "dbaidmsvideocreator.service",
  "dbaignss.service",
  "dbaiparkmode.service",
  "dbairmsframepublisher.service",
  "dbairms.service",
  "dbairmsvideocreator.service",
  "dbaiseatbelt.service",
  "dbaiserver.service",
  "dbaiupdate.service",
  "dbaiweb.service",
]

export const TemplateTypeTypes={
  'event': 'Event',
  'report': 'Report',
  'client_onboarding': 'Client Onboarding',
  'new_user_account_creation': 'New User Account Creation',
  'user_email_verification': 'User Email Verification',
  'reset_password_otp': 'Reset Password Otp',
  'eld_report_uploaded' : 'Eld Report Uploaded',
  'incident_update'  : 'Incident Update',
  'incident_create' : 'Incident Create'
}

export const incidentUserType={
  DBAI_TEAM : 'DBAI Team',
  DBAI_USER : 'DBAI User'

}

export const clientGroupTypes = {
  'account' : 'Account',
  'client_group' : 'Client Group',
  'sub_group' : 'Sub Group',
}

export const MaintenanceUserFilter = [
  {
      title: 'All',
      value: ''
  },
  {
      title: 'Reported By Me',
      value: 'reported_by_me'
  },
  {
      title: 'Assigned To Me',
      value: 'assigned_to_me'
  },
  {
      title: 'Assigned to My Groups',
      value: 'assigned_to_group'
  },
];
