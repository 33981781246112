const routes = {
  statistics: 'stats',
  authentication: {
    forgetPassword: 'forget-password',
  },
  emailVerification: 'verify-email',
  details: 'platform',
  containers: {
    termsAndCondition: 'term-and-condition',
    dashboard: 'dashboard',
    tracking: 'tracking',
    trackingv1: 'tracking-v1',
    events: 'events',
    odd: 'odd',
    onDemandData: 'on-demand-data',
    drivers: 'drivers',
    annotate: 'annotate',
    reports: 'reports',
    devices: 'devices',
    user: 'users',
    admin: 'admin',
    changePassword: 'change-password',
    userProfile: 'user-profile',
    manageAccount: 'manage-account',
    vehicle: 'vehicles',
    maintenance: 'maintenance',
    maintenanceSupport: 'maintenance-support',
    coaching: 'coaching',
    eldReports: 'eld-reports',
    frs: 'frs',
  },
  dashboard: {
    fleetInsight: 'fleetinsight',
  },
  tracking: {
    vehicle: 'vehicle',
    driver: 'driver',
  },
  event: {
    driverTagging: 'driver-tagging',
    videoAnnotation: 'video-annotation',
    eventAnotation: 'event-annotation',
  },
  odd: { onDemandData: 'on-demand-data' },
  driver: {
    create: 'create',
    edit: 'edit',
    profile: 'profile',
    detail: 'detail'
  },
  device: {
    create: 'create',
    createBulk: 'create-bulk',
    edit: 'edit',
  },
  role: {
    create: 'create'
  },
  admin: {
    apps: {
      roles: 'roles',
      apiKey: 'apiKey',
      uiModules: 'ui-modules',
      emailTemplate: 'email-template',
      emailConfiguration: 'email-configuration',
      configEditor: 'config-editor',
      configDefaultValues: 'config-default-values',
      profiles: 'profiles',
      mapProfiles: 'map-profiles',
      userGroups: 'userGroups',
      clientConfiguration: 'client-configuration',
      geoFencing: 'geo-fencing',
      clientGroups: 'client-groups',
      graphNodes: 'graph-nodes'
    }
  },
};

export default routes;
