export const GET_MASTER_DATA = 'GET_MASTER_DATA';
export const GET_EVENT_DATA = 'GET_EVENT_DATA';
export const FILTER_EVENT_DATA = 'GET_FILTER_DATA';
export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const GET_ADMIN_PAGE_CLIENT_LIST = 'GET_ADMIN_PAGE_CLIENT_LIST';
export const GET_ALL_CLIENT_LIST = 'GET_ALL_CLIENT_LIST';
export const GET_CLIENT_GROUP_LIST = 'GET_CLIENT_GROUP_LIST';
export const GET_ODD_VEHICLE_LIST = 'GET_ODD_VEHICLE_LIST';
export const GET_ODD_REQUESTED_DATA = 'GET_ODD_REQUESTED_DATA';
export const CLEAR_ODD_REQUESTED_DATA = 'CLEAR_ODD_REQUESTED_DATA';
export const ODD_REQUEST_UPDATE = 'ODD_REQUEST_UPDATE';
export const GET_DEVICE_LIST = 'GET_DEVICE_LIST';
export const GET_SAFEST_RISKIEST_DRIVER_LIST = 'GET_SAFEST_RISKIEST_DRIVER_LIST';
export const CLEAR_SAFEST_RISKIEST_DRIVER_LIST = 'CLEAR_SAFEST_RISKIEST_DRIVER_LIST';
export const OPEN_SENSOR_DATA_REQUEST = 'OPEN_SENSOR_DATA_REQUEST';
export const CLOSE_EVENT_DATA_REQUEST = 'CLOSE_EVENT_DATA_REQUEST';
export const UPDATE_SENSOR_DATA = 'UPDATE_SENSOR_DATA';
export const UPDATE_EVENT_DATA = 'UPDATE_EVENT_DATA';
export const UPDATE_SELECTED_VEHICLE = 'UPDATE_SELECTED_VEHICLE';

export const NOTIFICATION_DATA = 'NOTIFICATION_DATA';

export const CLEAR_DATA = 'CLEAR_DATA';
//WS RELATED ACTION TYPE
export const ESTABLISHED_WS_CONNECTION = 'ESTABLISHED_WS_CONNECTION';
export const ESTABLISHED_WS_CONNECTION_SUCCESS = 'ESTABLISHED_WS_CONNECTION_SUCCESS';

export const SET_GLOBAL_CLIENT_ID = "SET_GLOBAL_CLIENT_ID";
export const GET_GLOBAL_CLIENT_ID = "GET_GLOBAL_CLIENT_ID"; 
export const SET_CLIENT_PREFERENCES = "SET_CLIENT_PREFERENCES";
export const SET_RAW_VIDEO_AVAILABILITY = "SET_RAW_VIDEO_AVAILABILITY";
export const CLEAR_RAW_VIDEO_AVAILABILITY = "CLEAR_RAW_VIDEO_AVAILABILITY";
export const SET_MULTICAMERA_AVAILABILITY = "SET_MULTICAMERA_AVAILABILITY";
export const CLEAR_MULTICAMERA_AVAILABILITY = "CLEAR_MULTICAMERA_AVAILABILITY";
export const LOAD_MULTICAMERA_AVAILABILITY = "LOAD_MULTICAMERA_AVAILABILITY";
export const GET_DRIVER_COACHING_DATA = "SET_DRIVER_COACHING_DATA";
export const SET_DRIVER_COACHING_DATA = "SET_DRIVER_COACHING_DATA";
export const SET_AUDIO_CALL_PROGRESS = "SET_AUDIO_CALL_PROGRESS";
export const CLOUD_TELEPHONY = "CLOUD_TELEPHONY";
export const LIVE_STREAMING = "LIVE_STREAMING";
export const SERVICE_HANDLER = "SERVICE_HANDLER";
export const SERVICE_HANDLER_STATUS = "SERVICE_HANDLER_STATUS";
export const COMMAND_HANDLER = "COMMAND_HANDLER";
export const COMMAND_HANDLER_STATUS = "COMMAND_HANDLER_STATUS";
export const CLOUD_TELEPHONY_DEVICE_STATUS = "CLOUD_TELEPHONY_DEVICE_STATUS";
export const LIVE_STREAMING_STATUS = "LIVE_STREAMING_STATUS";
export const VEHICLE_CONFIGURATION = "VEHICLE_CONFIGURATION";
