import React, { Suspense, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from 'routes';
import { eventBus } from 'utils/uiHelper';
import Notification from 'components/elements/Notification';
import { socket } from 'socket/socketMiddleware';

/* font-family style sheet */
import '../styles/font-family/font-family.scss';
import { useDispatch, useSelector } from 'react-redux';

//components
const Spinner = React.lazy(() => import('../components/elements/Spinner'));
//containers
const Authentication = React.lazy(() => import('./authentication'));
const Details = React.lazy(() => import('./details'));
const Statistic = React.lazy(() => import('./statistic'));
const EmailVerification = React.lazy(() => import('./authentication/EmailVerification'));

function App(props) {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const globalClientIDFilter = useSelector(state => state.app.clientID);
  const clientID = userDetails?.user?.clientId;
  
  useEffect(() => {
    eventBus.on('logout', () => {
      logOut();
      
    });
    return () => {
      eventBus.remove('logout');
    };
  }, []);

  const logOut = () => {
    localStorage.removeItem('globalClient');
    props.history.push('/');
    Notification({
      message: 'Session has expired. Login again!',
      toastType: 'error',
      toastId: 'session-expire-issue',
    });
    socket?.disconnect();
  };

  return (
    <Suspense fallback={<Spinner fullScreen={true} />}>
      <Switch>
        <Route exact path={`${props.match.path}${routes.statistics}`} component={Statistic} />
        <Route
          path={`${props.match.path}${routes.containers.user}/${routes.emailVerification}`}
          component={EmailVerification}
        />

        <Route
          path={`${props.match.path}`}
          component={isEmpty(userDetails) ? Authentication : Details}
        />
      </Switch>
      <ToastContainer className="notification authentication" />
      </Suspense>
  );
}

export default App;
