import moment from 'moment';
import 'moment/locale/en-in';
import momentzone from 'moment-timezone';
import { isEmpty } from 'lodash';

export const MM_DD_YYYY = 'MM/DD/YYYY';

export const DD_MMM_YYYY = 'DD MMM YYYY';

export const formatDate = (date, format = MM_DD_YYYY) => {
  if (isEmpty(date)) {
    return date;
  }
  let formattedDate = moment(date).format(format);
  return formattedDate;
};

export const momentWithTimeZone = (date) => {
  const currentTimezone = localStorage.getItem('timezone') || momentzone.tz.guess();
  if (isEmpty(date)) {
    return momentzone().tz(currentTimezone);
  }
  return  momentzone.tz(date, currentTimezone)
}

export const getUnixTimeStamp = (date) => {
  const formattedDate = new Date(moment(date).format('MM/DD/YYYY'));
  return formattedDate.getTime();
};

export const getLocalOffsetTimeStamp = (date) => {
  const localDate = momentWithTimeZone(date).startOf('day').format();
  return new Date(localDate).getTime();
}

//will return date-range with last date as today
export const getDateRange = (days) => {
  const dateRange = {
    startDate: moment().subtract(days, 'days'),
    endDate: moment(),
  };
  return dateRange;
};

export const getDifferenceFromCurrentDate = (date, unit) => {
  const currentDate = moment();
  return currentDate.diff(date, unit);
};

export const getTimeInUnix = (timeStamp) => {
  const formatDate = moment(timeStamp);
  return formatDate?.unix() ?? 0;
};

export const convertSecondsToDuration = (secondDuration= 0) => {
  const duration = moment.duration(secondDuration, 'seconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const durationString = `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${(!hours && !minutes && seconds) ? seconds + 's' : ''}`;
  return durationString;
}

export const convertMinutesToDuration = (minuteDuration= 0) => {
  const duration = moment.duration(minuteDuration, 'minutes');
  const days = parseInt(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const durationString = `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}`;
  return durationString || '0m';
}

export const convertMinutesToDurationObject = (minuteDuration= 0) => {
  const duration = moment.duration(minuteDuration, 'minutes');
  const days = parseInt(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  return { days, hours, minutes };
}